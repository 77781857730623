@import '../../styles/main.scss';

// 1st variant
.mainContainer {
  position: relative;

  .ContentWithHeadingAndPara {
    max-width: $universalSize-980;
    text-align: center;
    margin: $universalSize-64 auto $universalSize-96;

    h2 {
      @include desktopHeading2();
    }

    h3 {
      @include desktopHeading3();
      @include respond(medium) {
        @include mobileHeading3();
      }
    }

    h4,
    h5,
    h6 {
      @include desktopHeading4();
      @include respond(medium) {
        @include mobileHeading4();
      }
    }

    p {
      @include desktopPara2();

      &:not(:first-of-type) {
        padding-top: $universalSize-24;
      }
    }

    p:first-of-type {
      margin-top: $universalSize-40;
    }
  }

  // second variant
  .ContentWithParaImageAndButton {
    text-align: center;
    max-width: $universalSize-980;
    margin: $universalSize-64 auto $universalSize-96;
    background-color: $background-silver-color;
    border-radius: $universalSize-40;
    padding: $universalSize-64;

    h2 {
      @include desktopHeading2();
    }

    h3 {
      @include desktopHeading3();
      @include respond(medium) {
        @include mobileHeading3();
      }
    }

    h4,
    h5,
    h6 {
      @include desktopHeading4();
      @include respond(medium) {
        @include mobileHeading4();
      }
    }

    p {
      @include desktopPara2();

      &:not(:first-of-type) {
        padding-top: $universalSize-24;
      }
    }

    img {
      width: 100%;
      height: 100%;
      border-radius: $universalSize-32;
    }

    p:first-of-type {
      margin-top: $universalSize-40;
    }

    p:last-of-type {
      text-align: center;
    }

    a {
      display: inline-block;
      padding: $universalSize-8 $universalSize-16;
      background-color: $button-background-color;
      color: $white-color;
      text-decoration: none;
      border-radius: $universalSize-8;
    }
  }

  // 3rd variant

  .colorBlendParent {
    background: rgb(245 239 251/1);
    @include colorBlendCircles(rgb(173 109 244/1), rgb(255 95 228/1), 320px, 320px);

    @include respond(medium) {
      @include colorBlendCircles(rgb(173 109 244/1), rgb(255 95 228/1), 214px, 214px);
    }
  }

  .ContentWithPurpleBackGround {
    text-align: center;
    width: 70%;
    margin: 0 auto;
    padding-top: $universalSize-64;
    padding-bottom: $universalSize-96;

    h2 {
      @include desktopHeading2();
    }

    h3 {
      @include desktopHeading3();
      @include respond(medium) {
        @include mobileHeading3();
      }
    }

    h4,
    h5,
    h6 {
      @include desktopHeading4();
      @include respond(medium) {
        @include mobileHeading4();
      }
    }

    p {
      @include desktopPara2();

      &:not(:first-of-type) {
        padding-top: $universalSize-24;
      }
    }

    p:first-of-type {
      margin-top: $universalSize-40;
    }
  }

  @include respond(medium) {
    // 1st variant
    .ContentWithHeadingAndPara {
      text-align: left;
      width: 100%;
      margin: $universalSize-32 auto$universalSize-40;
      padding: 0$universalSize-24;
      h2 {
        @include mobileHeading2();
      }

      p {
        @include mobilePara2();

        &:not(:first-of-type) {
          padding-top: $universalSize-16;
        }
      }

      p:first-of-type {
        margin-top: $universalSize-24;
      }
    }

    // 2 variant

    .ContentWithParaImageAndButton {
      text-align: left;
      width: 100%;
      padding: $universalSize-24;
      width: 90%;
      border-radius: $universalSize-24;
      h2 {
        @include mobileHeading2();
      }

      p {
        @include mobilePara2();

        &:not(:first-of-type) {
          padding-top: $universalSize-16;
        }
      }

      p:first-of-type {
        margin-top: $universalSize-24;
      }

      a {
        width: 100%;
      }
    }

    // 3rd variant
    .ContentWithPurpleBackGround {
      text-align: left;
      width: 100%;
      padding-right: 2rem;
      padding-left: 2rem;

      h2 {
        @include mobileHeading2();
      }

      p {
        @include mobilePara2();

        &:not(:first-of-type) {
          padding-top: $universalSize-16;
        }
      }

      p:first-of-type {
        margin-top: $universalSize-24;
      }
    }
  }
}

.justImageLikeBanner {
  max-width: $universalSize-980;
  margin: 4rem auto 6rem;
  text-align: center;

  img {
    width: 100%;
    height: 100%;
    border-radius: $universalSize-32;
  }

  .desktopContent {
    display: block;

    @include respond(medium) {
      display: none;
    }
  }

  .mobileContent {
    display: none;

    @include respond(medium) {
      display: block;
    }
  }
}
