@import '../../styles/main.scss';

.tabContainer {
  display: flex;
  flex-direction: column;
  width: 95%;
  overflow: scroll;
  padding-top: $universalSize-40;
  @include removeScrollBar();

  .tabButtons {
    display: flex;
    // padding: $universalSize-10;
    gap: $universalSize-10;
    margin: 0 auto;

    &::-webkit-scrollbar {
      display: none;
    }

    @include respond(medium) {
      overflow-x: auto;
      flex-shrink: 0;
    }

    @include respond(small) {
      overflow-x: auto;
      justify-content: flex-start;
      flex-shrink: 0;
    }

    button {
      background: none;
      border: none;
      cursor: pointer;

      display: flex;
      padding: $universalSize-16 $universalSize-24;
      border-radius: $universalSize-40;
      justify-content: center;
      align-items: center;
      width: max-content;

      p {
        font-size: $universalSize-18;
        font-style: normal;
        font-weight: 600;
        line-height: $universalSize-28;

        @include respond(medium) {
          font-size: $universalSize-14;
          line-height: $universalSize-20;
        }
      }
    }

    // className={`${nTabsActiveTab === mapKey ? styles.active : ''}`}
    // background-color: #f5f5f5;
    // button.active {
    //   background-color: $text-color;
    //   color: $plain-white-color;
    // }
  }

  .tabSectionHeading {
    // display: flex;
    // flex-direction: column;
    // justify-content: center;
    // align-items: center;
    max-width: $universalSize-980;
    margin: 0 auto;
    text-align: center;

    h3 {
      @include desktopHeading3();
      margin-top: $universalSize-24;

      @include respond(medium) {
        @include mobileHeading3();
        margin-top: $universalSize-16;
      }
    }

    p {
      @include commonParagraph(null, null, $text-color);
      &:not(:has(img)):not(:has(strong)) {
        margin: $universalSize-24 0rem 0rem 0rem;

        @include respond(medium) {
          margin: $universalSize-16 0rem 0rem 0rem;
        }
      }
    }
  }
}
