@import '../../styles/main.scss';

.rootContainer {
  position: relative;
  z-index: $zindex-grid-root-container;

  .colorBlendParent {
    background: rgb(245 239 251/1);
    @include colorBlendCircles(rgb(173 109 244/1), rgb(255 95 228/1), 320px, 320px);

    @include respond(medium) {
      @include colorBlendCircles(rgb(173 109 244/1), rgb(255 95 228/1), 214px, 214px);
    }
  }

  .container {
    padding: $universalSize-96 $universalSize-24;
    position: relative;
    z-index: $zindex-grid-root-container;

    @include respond(medium) {
      padding: $universalSize-40 $universalSize-24;
    }

    //NColumnGrid: Section background
    &.plainBackground {
      background: #fff;
    }

    &.linearGradientPink {
      background: linear-gradient(51deg, #FFF -0.2%, #FFF 2.07%), linear-gradient(0deg, #FFF 5.36%, #FFF 15.5%), radial-gradient(57.93% 52.99% at 56.11% 47.99%, rgba(255, 255, 255, 0.50) 0%, rgba(68, 210, 255, 0.19) 50%, rgba(255, 255, 255, 0.50) 100%), linear-gradient(236deg, #FFF 0.9%, rgba(101, 75, 255, 0.30) 24.99%, #FFF 69.33%), linear-gradient(235deg, #FFF 44.89%, rgba(136, 0, 255, 0.23) 62.23%);
      background: linear-gradient(51deg, color(display-p3 1 1 1) -0.2%, color(display-p3 1 1 1 / 0.00) 2.07%), linear-gradient(0deg, color(display-p3 1 1 1) 5.36%, color(display-p3 1 1 1 / 0.00) 15.5%), radial-gradient(57.93% 52.99% at 56.11% 47.99%, color(display-p3 1 1 1 / 0.00) 0%, color(display-p3 0.4392 0.8118 1 / 0.19) 50%, color(display-p3 1 1 1 / 0.00) 100%), linear-gradient(236deg, color(display-p3 1 1 1 / 0.00) 0.9%, color(display-p3 0.3804 0.298 1 / 0.30) 24.99%, color(display-p3 1 1 1 / 0.00) 69.33%), linear-gradient(235deg, color(display-p3 1 1 1 / 0.00) 44.89%, color(display-p3 0.4863 0.0784 0.9882 / 0.23) 62.23%);
    }

    //NColumnGrid: Section background
    .sectionHeading {
      margin: 0 auto;
      max-width: $universalSize-980;
      text-align: center;

      @include respond(small) {
        text-align: left;
      }

      h2 {
        @include desktopHeading2();

        @include respond(medium) {
          @include mobileHeading2();
        }
      }

      h3 {
        @include desktopHeading3();
        text-align: center;
    
        @include respond(medium) {
          @include mobileHeading3();
        }
      }

      p {
        @include commonParagraph($universalSize-24, $universalSize-16, $text-color-2);
      }
    }

    .sectionCta {
      max-width: $universalSize-980;
      margin: 0 auto;
      text-align: center;

      p {
        @include commonParagraph($universalSize-64, $universalSize-32, $text-color-2);
        font-weight: 600;
        @include respond(small) {
          text-align: left;
        }
      }
    }

    //NColumnGrid: For multi Column

    .grid {
      display: flex;
      flex-wrap: wrap;
      gap: $universalSize-24;
      padding-top: $universalSize-64;

      // Responsive styles
      @include respond(medium) {
        padding-top: $universalSize-32;

        &.columns-1,
        &.columns-2,
        &.columns-3 {
          flex-direction: column;
          justify-content: center;
          align-items: center;
        }
      }

      &.columns-0 {
        max-width: $universalSize-1200;
        margin: 0rem auto;
        .card {
          flex: 0 0 100%;
        }
      }

      &.columns-1 {
        max-width: $universalSize-980;
        margin: 0rem auto;
        .card {
          flex: 0 0 100%;
        }
      }

      &.columns-2 {
        max-width: $universalSize-980;
        margin: 0rem auto;
        justify-content: center;

        .card {
          flex: 0 0 calc(50% - $universalSize-24);
        }
      }

      &.columns-3 {
        max-width: $universalSize-980;
        margin: 0rem auto;
        .card {
          flex: 0 0 calc(33.33% - $universalSize-24);
        }
      }

      // Add styles for other column layouts if needed

      .card {
        &.cardBgLinear {
          padding: $universalSize-40;
          border-radius: $universalSize-40;
          background: #fbf7ff;
          // max-width: $max-card-width-size;
          width: 100%;
          height: auto;

          @include respond(medium) {
            padding: $universalSize-24;
            max-width: 100%;
          }

          &.FullWidthImage {
            span > p:first-of-type {
              height: 18.75rem;
              overflow: hidden;
              border-radius: 32px;

              @include respond(medium) {
                height: auto;
              }
            }
            span > p:first-of-type > img {
              width: 100%;
              height: 100%;
              object-fit: contain;
            }
          }

          &.active {
            display: block;
          }

          &.inactive {
            display: none;
          }

          &.notFullWidthImage {
            span > p > img {
              width: $universalSize-96;
              height: $universalSize-96;
              object-fit: contain;
            }
          }

          h3,
          h4,
          h5,
          h6 {
            @include desktopHeading4();

            // overflow: hidden;
            // display: -webkit-box;
            // -webkit-line-clamp: 2;
            // -webkit-box-orient: vertical;
            // text-overflow: ellipsis;

            margin-top: $universalSize-24;

            @include respond(medium) {
              @include mobileHeading4();
              margin-top: $universalSize-16;
            }
          }

          p {
            @include commonParagraph(null, null, $text-color);

            &:not(:has(img)):not(:has(strong)) {
              height: $universalSize-150;
              overflow: hidden;
              text-overflow: ellipsis;

              margin: $universalSize-24 0rem 0rem 0rem;

              @include respond(medium) {
                height: $universalSize-150;
                overflow: hidden;
                text-overflow: ellipsis;

                margin: $universalSize-16 0rem 0rem 0rem;
              }
            }
          }

          ul {
            list-style-type: disc;
            margin-left: 20px; 
            margin-top: 10px; 
          }
        
          li {
            @include commonParagraph(null, null, $text-color);
            margin-bottom: 5px; 
          }

          &.noCtaInCard {
            p {
              &:not(:has(img)):not(:has(strong)) {
                height: max-content;
              }
            }
          }
        }

        &.cardPlain {
          padding: $universalSize-32;
          border-radius: $universalSize-32;
          background: $plain-white-color;
          border: $universalSize-1 solid #e8e8e8;
          // max-width: $max-card-width-size;
          width: 100%;
          height: auto;

          @include respond(medium) {
            padding: $universalSize-24;
            max-width: 100%;
          }

          &.active {
            display: block;
          }

          &.inactive {
            display: none;
          }

          img {
            width: $universalSize-42;
            height: $universalSize-42;
            object-fit: contain;
          }

          h3,
          h4,
          h5,
          h6 {
            @include desktopHeading4();
            // display: -webkit-box;
            // -webkit-line-clamp: 2;
            // -webkit-box-orient: vertical;
            // text-overflow: ellipsis;

            margin-top: $universalSize-16;

            @include respond(medium) {
              @include mobileHeading4();
            }
          }

          p {
            @include commonParagraph(null, null, $text-color);

            &:not(:has(img)):not(:has(strong)) {
              height: $universalSize-150;
              overflow: hidden;
              text-overflow: ellipsis;
              margin: $universalSize-16 0rem 0rem 0rem;

              @include respond(medium) {
                height: $universalSize-150;
                overflow: hidden;
                text-overflow: ellipsis;
                margin: $universalSize-8 0rem 0rem 0rem;
              }
            }
          }

          ul {
            list-style-type: disc;
            margin-left: 20px; 
            margin-top: 10px; 
          }
        
          li {
            @include commonParagraph(null, null, $text-color);
            margin-bottom: 5px; 
          }

          &.noCtaInCard {
            p {
              &:not(:has(img)):not(:has(strong)) {
                height: max-content;
              }
            }
          }
        }

        &.cardWithBottomImage {
          border-radius: $universalSize-32;
          background: #fbf7ff;
          // max-width: $max-card-width-size;
          width: 100%;
          height: auto;

          @include respond(medium) {
            max-width: 100%;
          }

          &.active {
            display: block;
          }

          &.inactive {
            display: none;
          }

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }

          h3,
          h4,
          h5,
          h6 {
            @include desktopHeading4();
            // display: -webkit-box;
            // -webkit-line-clamp: 2;
            // -webkit-box-orient: vertical;
            // text-overflow: ellipsis;

            padding: $universalSize-32 $universalSize-32 0rem $universalSize-32;

            @include respond(medium) {
              @include mobileHeading4();
              padding: $universalSize-24 $universalSize-24 0rem $universalSize-24;
            }
          }

          p {
            @include commonParagraph(null, null, $text-color);
            padding: $universalSize-16 $universalSize-32 $universalSize-32 $universalSize-32;
            height: $universalSize-150;
            overflow: hidden;
            text-overflow: ellipsis;

            &:has(img) {
              padding: 0rem;
            }
            @include respond(medium) {
              padding: $universalSize-16 $universalSize-24 $universalSize-24 $universalSize-24;
            }
          }

          ul {
            list-style-type: disc;
            margin-left: 20px; 
            margin-top: 10px; 
          }
        
          li {
            @include commonParagraph(null, null, $text-color);
            margin-bottom: 5px; 
          }

          &.noCtaInCard {
            p {
              &:not(:has(img)):not(:has(strong)) {
                height: max-content;
              }
            }
          }
        }

        &.cardOffWhite {
          padding: $universalSize-32;
          border-radius: $universalSize-32;
          border: $universalSize-1 solid $plain-white-color;
          backdrop-filter: blur($universalSize-12);
          background: rgba(255, 255, 255, 0.5);
          // max-width: $max-card-width-size;
          width: 100%;
          height: auto;

          @include respond(medium) {
            padding: $universalSize-24;
            max-width: 100%;
          }

          &.active {
            display: block;
          }

          &.inactive {
            display: none;
          }

          h3,
          h4,
          h5,
          h6 {
            @include desktopHeading4();
            // display: -webkit-box;
            // -webkit-line-clamp: 2;
            // -webkit-box-orient: vertical;
            // text-overflow: ellipsis;

            margin-top: $universalSize-16;

            @include respond(medium) {
              @include mobileHeading4();
            }
          }

          p {
            @include commonParagraph(null, null, $text-color);

            &:not(:has(img)):not(:has(strong)) {
              height: $universalSize-150;
              overflow: hidden;
              text-overflow: ellipsis;

              margin: $universalSize-16 0rem 0rem 0rem;
              @include respond(medium) {
                height: $universalSize-150;
                overflow: hidden;
                text-overflow: ellipsis;

                margin: $universalSize-8 0rem 0rem 0rem;
              }
            }
          }

          ul {
            list-style-type: disc;
            margin-left: 20px; 
            margin-top: 10px; 
          }
        
          li {
            @include commonParagraph(null, null, $text-color);
            margin-bottom: 5px; 
          }

          &.noCtaInCard {
            p {
              &:not(:has(img)):not(:has(strong)) {
                height: max-content;
              }
            }
          }
        }

        &.downloadDocument {
          @extend .cardOffWhite;
          text-align: center;
        }

        &.greyCardWithCirclePattern {
          position: relative;
          padding: $universalSize-32;
          border-radius: $universalSize-32;
          background: linear-gradient(180deg, #fbf7ff 0%, #f9f8fc 45.31%, #f5f2f9 100%);
          // max-width: $max-card-width-size;
          width: 100%;
          height: auto;

          @include respond(medium) {
            padding: $universalSize-32 $universalSize-24;
            max-width: 100%;
          }

          &.FullWidthImage {
            span > p > img {
              width: 100%;
              height: 100%;
              object-fit: contain;
            }
          }

          &.active {
            display: block;
          }

          &.inactive {
            display: none;
          }

          &.notFullWidthImage {
            span > p > img {
              width: $universalSize-42;
              height: $universalSize-42;
              object-fit: contain;
            }
          }

          .circle-pattern {
            position: absolute;
            top: 0rem;
            right: 0rem;
            z-index: $zindex-grid-circle-pattern;

            img {
              border-top-right-radius: $universalSize-32;
            }
          }

          h3,
          h4,
          h5,
          h6 {
            @include desktopHeading4();
            // display: -webkit-box;
            // -webkit-line-clamp: 2;
            // -webkit-box-orient: vertical;
            // text-overflow: ellipsis;

            margin-top: $universalSize-16;

            @include respond(medium) {
              @include mobileHeading4();
            }
          }

          p {
            @include commonParagraph(null, null, $text-color);

            &:not(:has(img)):not(:has(strong)) {
              height: $universalSize-150;
              overflow: hidden;
              text-overflow: ellipsis;

              margin: $universalSize-16 0rem 0rem 0rem;
              @include respond(medium) {
                height: $universalSize-150;
                overflow: hidden;
                text-overflow: ellipsis;

                margin: $universalSize-8 0rem 0rem 0rem;
              }
            }
          }

          ul {
            list-style-type: disc;
            margin-left: 20px; 
            margin-top: 10px; 
          }
        
          li {
            @include commonParagraph(null, null, $text-color);
            margin-bottom: 5px; 
          }

          &.noCtaInCard {
            p {
              &:not(:has(img)):not(:has(strong)) {
                height: max-content;
              }
            }
          }
        }

        &.stepCardsOffWhite {
          padding: $universalSize-32;
          border-radius: $universalSize-32;
          border: $universalSize-1 solid $plain-white-color;
          backdrop-filter: blur($universalSize-12);
          background: rgba(255, 255, 255, 0.5);
          // max-width: $max-card-width-size;
          width: 100%;
          height: auto;

          @include respond(medium) {
            padding: $universalSize-24;
            max-width: 100%;
          }

          &.FullWidthImage {
            span > p > img {
              width: 100%;
              height: 100%;
              object-fit: contain;
            }
          }

          &.notFullWidthImage {
            span > p > img {
              width: $universalSize-92;
              height: $universalSize-32;
              object-fit: contain;
            }
          }

          h3,
          h4,
          h5,
          h6 {
            @include desktopHeading4();

            // display: -webkit-box;
            // -webkit-line-clamp: 2;
            // -webkit-box-orient: vertical;
            // text-overflow: ellipsis;

            margin-top: $universalSize-16;

            @include respond(medium) {
              @include mobileHeading4();
            }
          }

          p {
            @include commonParagraph(null, null, $text-color);

            &:not(:has(img)):not(:has(strong)) {
              height: $universalSize-150;
              overflow: hidden;
              text-overflow: ellipsis;

              margin: $universalSize-16 0rem 0rem 0rem;
              @include respond(medium) {
                height: $universalSize-150;
                overflow: hidden;
                text-overflow: ellipsis;

                margin: $universalSize-8 0rem 0rem 0rem;
              }
            }
          }

          ul {
            list-style-type: disc;
            margin-left: 20px; 
            margin-top: 10px; 
          }
        
          li {
            @include commonParagraph(null, null, $text-color);
            margin-bottom: 5px; 
          }

          &.noCtaInCard {
            p {
              &:not(:has(img)):not(:has(strong)) {
                height: max-content;
              }
            }
          }
        }

        &.recentArticlesCard {
          width: 100%;
          height: auto;
          background: $plain-white-color;
          border-radius: $universalSize-32;


          @include respond(medium) {
            max-width: 100%;
          }

          &.FullWidthImage {
            span > p > img {
              width: 100%;
              height: 100%;
              object-fit: contain;
            }
          }

          &.notFullWidthImage {
            padding:  $universalSize-32;
          }

          h3,
          h4,
          h5,
          h6 {
            @include desktopHeading4();

            // display: -webkit-box;
            // -webkit-line-clamp: 2;
            // -webkit-box-orient: vertical;
            // text-overflow: ellipsis;

            margin-top: $universalSize-16;

            @include respond(medium) {
              @include mobileHeading4();
            }
          }

          p {
            @include commonParagraph(null, null, $text-color);

            &:not(:has(img)):not(:has(strong)){
              height: $universalSize-150;
              overflow: hidden;
              text-overflow: ellipsis;

              margin: $universalSize-16 0rem 0rem 0rem;
              @include respond(medium) {
                height: $universalSize-150;
                overflow: hidden;
                text-overflow: ellipsis;

                margin: $universalSize-8 0rem 0rem 0rem;
              }
            }
          }

          ul {
            list-style-type: disc;
            margin-left: 20px; 
            margin-top: 10px; 
          }
        
          li {
            @include commonParagraph(null, null, $text-color);
            margin-bottom: 5px; 
          }

          &.noCtaInCard {
            p {
              &:not(:has(img)):not(:has(strong)) {
                height: max-content;
              }
            }
          }

          &.active {
            display: block;
          }

          &.inactive {
            display: none;
          }
        }
      }
    }

    .cardCta {
      background: none;
      border: none;
      padding: 0;
      cursor: pointer;

      &.ctaWithinCard {
        margin-top: $universalSize-24;

        @include respond(medium) {
          margin-top: $universalSize-16;
          width: 100%;
        }
      }

      &.ctaOutsideCard {
        margin-top: $universalSize-32;

        @include respond(medium) {
          margin-top: $universalSize-24;
          width: 100%;
        }
      }

      &.CardCtaWithRightArrow {
        &.ctaWithinCard {
          @include respond(medium) {
            display: flex;
            justify-content: flex-start;
            align-items: center;
          }
        }

        &.ctaOutsideCard {
          margin-left: auto;
          margin-right: auto;
          @include respond(small) {
            display: flex;
            justify-content: flex-start;
          }
        }

        span {
          background: linear-gradient(91deg, #6833e2 -44.74%, #e60fbd 74.96%);
          -webkit-background-clip: text;
          color: transparent;
          font-size: $universalSize-18;
          font-style: normal;
          font-weight: 500;
          line-height: $universalSize-28;
          margin-right: $universalSize-12;
        }

        img {
          width: $universalSize-24 !important;
          height: $universalSize-24 !important;
          object-fit: cover;
          vertical-align: middle;
          // margin-bottom: $universalSize-5;
        }
      }

      &.GreenSubmitCta {
        span {
          text-align: center;
          font-size: $universalSize-16;
          font-style: normal;
          font-weight: 600;
          line-height: $universalSize-32;
          color: #fff;
          background: #0fa457;
          border-radius: $universalSize-8;
          padding: $universalSize-16;

          /* Apply full width on mobile */
          @include respond(medium) {
            width: 100%;
            display: block;
          }
        }

        img {
          display: none;
        }
      }

      &.documentDownload {
        @extend .GreenSubmitCta;
        display: block;
        margin: $universalSize-48 auto;
      }
    }
  }
}
