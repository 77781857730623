@import '../../../styles/main.scss';

.productAtfPageInfoRootContainer {
  background: #f6f7fd;
  padding: $universalSize-24 0 $universalSize-10 0;

  @media (min-width: 761px) and (max-width: 1125px) {
    padding: $universalSize-12 $universalSize-20;
  }

  @include respond(medium) {
    padding: $universalSize-12 $universalSize-20;
  }

  .productAtfPageInfoMainContainer {
    display: flex;
    flex-direction: column;
    max-width: $universalSize-980;
    margin: 0 auto;
    padding: $universalSize-24;
    border-radius: $universalSize-24;
    border: 1px solid #e7e7f2;
    background: rgba(255, 255, 255, 0.3);

    @include respond(medium) {
      padding: $universalSize-24 $universalSize-12;
    }

    .headingAtfPage {
      color: #611bcd;
      font-size: $universalSize-14;
      font-style: normal;
      font-weight: 500;
      line-height: $universalSize-20;
      letter-spacing: $universalSize-1;
      text-transform: uppercase;
    }

    .descriptionContainer {
      margin-top: 0.8125rem;
      line-height: $universalSize-24;

      .descriptionAtfPage {
        color: #040224;
        font-size: $universalSize-16;
        font-style: normal;
        font-weight: 400;
        line-height: $universalSize-24;
        display: none;

        &.showFull {
          display: block;
        }
      }

      .shortenedDescriptionAtfPage {
        color: #040224;
        font-size: $universalSize-16;
        font-style: normal;
        font-weight: 400;
        line-height: $universalSize-24;

        .readMoreShowLessButton {
          background: none;
          border: none;
          cursor: pointer;
          color: #1b73e8;

          font-size: $universalSize-16;
          font-style: normal;
          font-weight: 500;
          line-height: $universalSize-24;
        }
      }
    }
  }

  .showDesktop {
    display: block;

    @include respond(medium) {
      display: none;
    }
  }

  .showMweb {
    display: none;

    @include respond(medium) {
      display: block;
    }
  }
}

.semPageStyle{
  background: none;
  .productAtfPageInfoMainContainer{
    border: none;
  }
}
