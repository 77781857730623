@import '../../../styles/main.scss';

.productBreadCrumbContainer {
  display: flex;
  justify-content: flex-start;
  padding: $universalSize-24 0rem;
  max-width: $universalSize-980;
  margin: 0 auto;

  @media (min-width: 761px) and (max-width: 1125px) {
    padding: $universalSize-24;
    flex-wrap: wrap;
  }

  @include respond(medium) {
    padding: $universalSize-24;
    flex-wrap: wrap;
  }

  .productAnchorTags {
    display: flex;
    align-items: center;
  }

  .mainVal {
    color: $text-color;
    font-size: $universalSize-14;
    font-style: normal;
    font-weight: 400;
    line-height: $universalSize-20;
  }

  a {
    color: #5c5677;
    font-size: $universalSize-14;
    font-style: normal;
    font-weight: 400;
    line-height: $universalSize-20;
  }

  .breadcrumbDivider {
    margin: 0 $universalSize-6;
    font-size: $universalSize-10;
    font-weight: 600;
  }
}
